import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography.js"

export default function ideaPage({ data }) {
  return (
    <div
      css={css`
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      `}
    >
      <title> Ideas - Josh Burns</title>

      <Layout>
        <br />
        <br />

        <h1
          css={css`
            overflow-wrap: break-word;
          `}
        >
          {" "}
          Ideas
        </h1>
        <h3>
          {" "}
          These are 'microblogs'. Ideas that may or may not have legs, that
          might be explored further and documented in a full blog article. Feel
          free to read, but do take anything in this section as opinion and full
          of potential error.
        </h3>
        <span>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div
              css={css`
                // padding: ${rhythm(1)};
              `}
            >
              <Link to={node.fields.slug}>
                <span
                  css={css`
                    margin: ${rhythm(0.2)};
                  `}
                >
                  <h2>{node.frontmatter.title} </h2>
                </span>
              </Link>
              <div>
                <i> ({node.frontmatter.date} )</i>
              </div>
              <span>
                <i>{node.excerpt}</i>
              </span>
            </div>
          ))}
        </span>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "idea" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            permalink
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
